<template>
  <div class="p-portfolio-card-wrap">
    <b-card :title="title" class="p-portfolio-card mb-3 mb-lg-4">
      <b-card-text class="p-portfolio-card-text">
        <p>{{ description }}</p>
        <div
          :style="{ backgroundImage: `url('${thumb}')` }"
          class="p-portfolio-card-img"
        />
        <div
          :style="{ backgroundImage: `url('${thumb}')` }"
          class="p-portfolio-card-img p-portfolio-card-img-hover"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "PortfolioItem",
  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    thumb: { type: String, default: "" },
  },
};
</script>

<style lang="scss">
.p-portfolio-card {
  transition: all 0.3s;

  &-wrap {
    color: $body-color;

    .p-portfolio-card-img-hover {
      display: none;
    }

    &:hover {
      .p-portfolio-card-img {
        display: none;
      }

      .p-portfolio-card-img-hover {
        display: block;
      }
    }
  }

  &:hover {
    border-color: $primary;
    background-color: $primary;

    .p-portfolio-card-text p {
      color: $body-color;
    }
  }

  &-text {
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      height: 84px;
      color: $gray-600;
    }
  }

  &-img {
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &-hover {
      display: none;
    }
  }
}
</style>
