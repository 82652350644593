<template>
  <layout-sub name="portfolio">
    <b-row>
      <b-col v-for="(el, i) in list" :key="i" cols="12" sm="6" lg="4" xl="3">
        <a
          v-if="el.url"
          :href="el.url"
          target="_blank"
          class="p-portfolio-link"
        >
          <PortfolioItem
            :title="el.title"
            :description="el.description"
            :thumb="el.thumb"
            class="p-portfolio-item"
          />
          <b-icon-box-arrow-up-right />
        </a>
        <span v-else>
          <PortfolioItem
            :title="el.title"
            :description="el.description"
            :thumb="el.thumb"
            class="p-portfolio-item"
          />
        </span>
      </b-col>
    </b-row>
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";
import { fatchCurrent } from "@/api";
import PortfolioItem from "./components/PortfolioItem.vue";

export default {
  name: "Portfolio",
  components: { LayoutSub, PortfolioItem },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    // 获取当前页面内容
    this.init();
  },
  methods: {
    async init() {
      const { data } = await fatchCurrent(30);
      if (data) {
        this.list = data;
      }
    },
  },
};
</script>

<style lang="scss">
.p-portfolio-link {
  position: relative;
  display: block;

  .b-icon {
    position: absolute;
    top: 25px;
    right: 20px;
    color: $white;
  }

  &:hover {
    text-decoration: none !important;

    .b-icon {
      color: $body-color;
    }
  }
}
</style>
